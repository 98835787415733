import React, { Component } from 'react'
import BookmarksContext from '../BookmarkContext'
import config from '../config'
import './EditBookmark.css';

const Required = () => (
    <span className='AddBookmark__required'>*</span>
  )
  
class EditBookmark extends Component {
    static contextType = BookmarksContext
  
    state = {
        error: null,
        id: '',
        title: '',
        url: '',
        description: '',
        rating: 1,
    };
    componentDidMount() {
        const { bookmarkId } = this.props.match.params
        fetch(config.API_ENDPOINT + `/${bookmarkId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${config.API_KEY}`
            }
        })
        .then(res => {
            if (!res.ok)
                return res.json().then(error => Promise.reject(error))
    
            return res.json()
        })
        .then(responseData => {
            this.setState({
                id: responseData.id,
                title: responseData.title,
                url: responseData.url,
                description: responseData.description,
                rating: responseData.rating,
            })
        })
        .catch(error => {
            console.error(error)
            this.setState({ error })
        })
    }
    handleChangeTitle = e => {
        this.setState({ title: e.target.value })
    };
    
      handleChangeUrl = e => {
        this.setState({ url: e.target.value })
    };
    
      handleChangeDescription = e => {
        this.setState({ description: e.target.value })
    };
    
      handleChangeRating = e => {
        this.setState({ rating: e.target.value })
    };
    handleSubmit = e => {
        e.preventDefault()
        // get the form fields from the event
        let { id, title, url, description, rating } = this.state
        let newBookmark = { id, title, url, description, rating }
        console.log(newBookmark)
        this.setState({ error: null })
        fetch(config.API_ENDPOINT + `/${id}`, {
            method: 'PATCH',
            body: JSON.stringify(newBookmark),
            headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${config.API_KEY}`
            }
        })
            .then(res => {
            if (!res.ok) {
                // get the error message from the response,
                return res.json().then(error => {
                // then throw it
                throw error
                })
            }
             //res.json() throwing errors
            return res
            })
            .then(newfields=> {
                this.setState({
                    id: newfields.id || '',
                    title: newfields.title || '',
                    url: newfields.url || '',
                    description: newfields.description || '',
                    rating: newfields.rating || '',
                })
  
                this.context.updateBookmark(newBookmark)
                this.props.history.push('/')  
            })
            .catch(error => {
                this.setState({ error })
            })
        }
    
    handleClickCancel = () => {
        this.props.history.push('/')
    }
    render() {
        const { error, title, url, description, rating } = this.state
        return (
            <section className='AddBookmark'>
                <h2>Update a bookmark</h2>
                <form
                    className='AddBookmark__form'
                    onSubmit={this.handleSubmit}
                >
                    <div className='AddBookmark__error' role='alert'>
                    {error && <p>{error.message}</p>}
                    </div>
                    <div>
                    <label htmlFor='title'>
                        Title
                        {' '}
                        <Required />
                    </label>
                    <input
                        type='text'
                        name='title'
                        id='title'
                        value={title}
                        onChange={this.handleChangeTitle}
                        required
                    />
                    </div>
                    <div>
                    <label htmlFor='url'>
                        URL
                        {' '}
                        <Required />
                    </label>
                    <input
                        type='url'
                        name='url'
                        id='url'
                        value={url}
                        onChange={this.handleChangeUrl}
                        required
                    />
                    </div>
                    <div>
                    <label htmlFor='description'>
                        Description
                    </label>
                    <textarea
                        name='description'
                        id='description'
                        value={description}
                        onChange={this.handleChangeDescription}
                    />
                    </div>
                    <div>
                    <label htmlFor='rating'>
                        Rating
                        {' '}
                        <Required />
                    </label>
                    <input
                        type='number'
                        name='rating'
                        id='rating'
                        value={rating}
                        onChange={this.handleChangeRating}
                        min='1'
                        max='5'
                        required
                    />
                    </div>
                    <div className='AddBookmark__buttons'>
                    <button type='button' onClick={this.handleClickCancel}>
                        Cancel
                    </button>
                    {' '}
                    <button type='submit'>
                        Save
                    </button>
                    </div>
                </form>
            </section>
        );
    }
}
  
  export default EditBookmark;
  